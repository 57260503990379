.form-wrapper 
{
    width: 50%;
}

.success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.fail {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

.upload {
    border-color: blue;
    cursor: pointer;
}